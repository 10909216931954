import { Subscriber, combineLatest } from 'rxjs';
import { getContextByUrl, getCookieDomainByLang } from 'src/app/core/utils/filter.utils';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DemoService} from '../../../../agora/demo/services/demo.service';
import {DemoModel} from '../../../../agora/demo/models/demo.model';
import {JWTService} from 'src/app/core/services/jwt.service';
import {CookieService} from 'ngx-cookie-service';
import {TriggerService} from 'src/app/core/services/trigger.service';
import {StorageService} from 'src/app/core/services/storage.service';
import {GetDatasService} from "../../../../../core/services/get-datas.service";
import {getCookieDomainByUrl, getRootUrl} from "../../../../../core/utils/filter.utils";
import {ErrorService} from "../../../../../core/services/error.service";
import {ErrorType} from "../../../../../core/enums/errorType.enum";
import {TranslateModuleConfig, TranslateService} from "@ngx-translate/core";
import {TranslationService} from "../../../../../core/services/translation.service";
import { OrderService } from 'src/app/core/services/order.service';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-progress-demo',
  templateUrl: './progress-demo.component.html',
  styleUrls: ['./progress-demo.component.scss'],
})
export class ProgressDemoComponent implements OnInit {

  demo: DemoModel;
  locale: string;
  accept = false;
  isAuthenticated = false;

  registrationDone = false;
  goToDemo = false;
  isOptin = false;

  clientData: any;

  demoCode:string;
  delegateId:string;
  lang:string;

  constructor(
    private route: ActivatedRoute,
    private demoService: DemoService,
    private router: Router,
    private cookie: CookieService,
    private jwtService: JWTService,
    private trigger: TriggerService,
    private storage: StorageService,
    public dataServices: GetDatasService,
    public error: ErrorService,
    public translateConfig: TranslationService,
    public translate:TranslateService,
    private orderService:OrderService,
    private location:Location
  ) {

  }

  ngOnInit() {
    this.locale = this.cookie.get("v_locale")?.replace("_","-");

    // this.lang = this.route.snapshot.params?.id;

    // if(this.lang){
    //   this.translate.use(this.lang.replace("-","_"));
    // }

    this.translateConfig.localesLoaded.subscribe(loaded => {
      if(loaded && this.demo){
        this.translate.use(this.demo.locale.replace("-","_"))
      }
    })

    this.registrationDone = false;
    this.goToDemo = this.route.snapshot.queryParams?.state=="joined";

    this.demoCode = this.route.snapshot.params?.demoCode;
    this.delegateId = this.route.snapshot.params?.delegateId;

    //Transform DelegateCode into delegateId
    this.delegateId = this.delegateId?.split("-")[0];

    this.demoService.getDemoByCode(this.demoCode, this.delegateId);

    this.isAuthenticated = this.jwtService.isAuthenticated();

    if(this.isAuthenticated){
      this.dataServices.clientData$.subscribe(data => {
        if (data) {
          this.clientData = data;
          if(this.goToDemo==true){
            this.join();
          }
        } else if(this.isAuthenticated){
          this.dataServices.getClientDatas(this.jwtService.getSubFromToken());
        }
      });
    }

    const progressDemoSubscribe = this.demoService.progressDemo$
    const panierOrderSubscribe = this.orderService.panierOrder$

    combineLatest([progressDemoSubscribe, panierOrderSubscribe]).subscribe(([progress, order]) => {
        this.handleProgressDemoSubscription(progress)
        this.handlePanierDemoSubscription(order)
    })

    // this.demoService.progressDemo$.subscribe(res => {
    //   if (res) {
    //     this.demo = res;
    //     //Automatically register the client for Futur Demo Once the user has been authenticated
    //     if(this.demo.statusId === 1 && this.registrationDone) this._checkOrCreateClient();
    //     if(this.goToDemo==true){
    //       this.join();
    //     }
    //   }
    // });

    // this.orderService.panierOrder$.subscribe(order => {
    //   if(order){
    //     this.checkDemoIdDelegateIdCookiesAndValidity(this.demoCode, this.delegateId, order);
    //   }
    // })
  }

  private handleProgressDemoSubscription(res){
    if (res) {
      this.demo = res;
      this.handleChangeLanguageIfMismatch();

      //Automatically register the client for Futur Demo Once the user has been authenticated
      if(this.demo.statusId === 1 && this.registrationDone) this._checkOrCreateClient();
      if(this.goToDemo==true){
        this.join();
      }
    }
  }

  private handlePanierDemoSubscription(order){
    if(order){
      this.checkDemoIdDelegateIdCookiesAndValidity(this.demoCode, this.delegateId, order);
    }
  }

  private checkDemoIdDelegateIdCookiesAndValidity(demoId:string, delegateId:string, guestOrder:ClientOrder){
    if(!this.demo) return;

    const contextId = getContextByUrl(window.location.origin);

    const storedDemoId = this.storage.demoId
    const storedDelegateId = this.storage.delegateId

    if(demoId && delegateId && demoId==storedDemoId && delegateId==storedDelegateId) return

    if(demoId && delegateId){
        if(!storedDemoId || !storedDelegateId || guestOrder.demoStatusId != 2){
          //Check Demo Validity
          this.orderService.checkDemoCodeValidity(contextId, demoId).subscribe(result => {

            if(guestOrder.demoStatusId != 2 || !storedDemoId) {
              this.storage.demoId = demoId
              this.storage.delegateId = delegateId
            }

            if(guestOrder.demoStatusId != 2 || !storedDelegateId) this.storage.delegateId = result.data.delegateId

            this.cookie.set("v_last_link_type","eso",30,"/", getCookieDomainByLang(result.data.locale?.replace("-","_")));
            this.orderService.getGuestOrder(delegateId, demoId)

          }, err => {

          })
        }else {
          this.cookie.set("v_last_link_type","eso",30,"/", getCookieDomainByLang(this.locale?.replace("-","_")));
        }
    } else if(delegateId){
      this.demoService.getDelegateDatas(delegateId).subscribe(result => {
          if(result && result.data && result.data.isActive) {
            this.storage.delegateId = delegateId
            this.cookie.set("v_last_link_type","eso",30,"/", getCookieDomainByLang(result.data.locale?.replace("-","_")));
          }
      })
    }
  }

  goToCart(){
    //this.storage.setCurrentDemoCookies(this.demo);
    this.storage.setDemoLogCookie(this.demo)
    this.router.navigate([`/${this.locale}/public/cart`]);
  }

  goToEshop(){
    const region = this.cookie.get("active_language");
    window.location.href= getRootUrl(this.cookie.get("v_locale"), region);
  }

  join(){
    if(this.jwtService.isAuthenticated() && this.demo && this.clientData) {
      this.storage.demoOrderId = this.demo.demoOrderId;
      // this.cookie.set('demoId', this.demo.demoCode, 365, '/', getCookieDomainByUrl(window.location.origin));
      // this.cookie.set('demoOrderId', this.demo.demoOrderId.toString(), 365, '/', getCookieDomainByUrl(window.location.origin));
      this._checkOrCreateClient();
    }
  }

  authenticate(param) {
    this.storage.redirectTo = `/${this.locale}/public/demo/${this.route.snapshot.params?.delegateId}/${this.route.snapshot.params?.demoCode}/${this.route.snapshot.params?.hostessName??''}?${param}`
    this.trigger.requestLogin();
  }

  getDemoDate(demoDate: Date) {
    return new Date(demoDate).setHours(0, 0, 0, 0);
  }

  getCurrentDate() {
    return new Date().setHours(0, 0, 0, 0);
  }

  getRemainingDays(date: Date) {
    const currentDate = new Date();
    const dateSent = new Date(date);
    return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));

  }

  getSiteUrl(){
    const region = this.cookie.get("active_language");
    return getRootUrl(this.cookie.get("v_locale"), region)
  }

  navigateUrl(url: string) {
    window.location.href = url;
  }

  private _checkOrCreateClient() {
    if(this.demo && this.clientData) {

      this.demoService.createClientByCode(this.demo.demoCode, this.clientData.clientCode).subscribe(datas => {
        if(datas && this.demo.statusId>1) {
          const routeToGo = `/${this.locale}/myvictoria/demo/${this.demo.demoCode}`;
          this.navigateUrl(routeToGo);
        } else this.registrationDone = true;
      });
    }
  }

  private handleChangeLanguageIfMismatch(){
    const vLocale = this.cookie.get("v_locale")

    if(!vLocale || vLocale != this.demo.locale){
      this.cookie.set("v_locale", this.demo.locale, 365, '/', getCookieDomainByLang(this.demo.locale))

      this.storage.languageCode = this.demo.locale
      this.locale =  this.cookie.get("v_locale")?.replace("_","-")

      //Load Proper Json Lang File
      this.translateConfig.loadJsonFiles()
      //

      this.location.go(window.location.pathname.replace(vLocale?.replace("_","-"), this.demo?.locale?.replace("_","-")))
    }
  }
}
