import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MollieGetLinkLoad } from '../models/mollieGetLinkLoad.model';
import { JWTService } from './jwt.service';
import { getApiUrlFromOrigin } from '../utils/filter.utils';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private _paymentOptions$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public paymentOptions$: Observable<any> = this._paymentOptions$.asObservable();
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private jwt: JWTService
  ) {}

  public getPaymentsOptions(contextId: number, lang: string): void {
    if (contextId != 1) {
      this.http.get<any>(`${BASE_URL}/mollie/getPaymentsMethodList/${contextId}/${lang}`).subscribe(res => {
        //FOR PRESENTATION STEERCO ONLY
        // let methods = {'count':2, 'items':[{'description':"LyfPay Direct", 'id':"Direct", "image":{"svg":undefined}}, {'description':"LyfPay 3X", 'id':"3X", "image":{"svg":undefined}}]}
        // res.data.items.push(...methods.items);
        // res.data.count = res.data.count + methods.count

        if (res.data) this._paymentOptions$.next(res.data);
      });
    } else {
      // const methods = {'count':2, 'items':[{'description':"CB Direct", 'id':"Direct", "image":{"svg":undefined}}, {'description':"CB 3X", 'description2':'Option disponible pour un montant supérieur à 90 €', 'id':"3X", "image":{"svg":undefined}}]}
      const methods = { count: 1, items: [{ description: 'CB Direct', id: 'Direct', image: { svg: undefined } }] };
      this._paymentOptions$.next(methods);
    }
  }

  public hasPaymentsOptions() {
    return this._paymentOptions$.getValue() != undefined;
  }

  public getMolliePaymentLink(contextId: number, data: MollieGetLinkLoad): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/mollie/getLink/${contextId}`, data);
  }

  public getLyfPayPaymentLink(data: MollieGetLinkLoad): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    return this.http.post<any>(`${BASE_URL}/lyfpay/getlyflink`, data, { headers: this.headers });
  }

  public getExistingPayment(contextId: number, paymentRef: string): Observable<any> {
    this.headers = new HttpHeaders();
    // this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getJWT());

    return this.http.get(`${BASE_URL}/mollie/getPayment/${contextId}?id=${paymentRef}`, { headers: this.headers });
  }
}
