import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrderService } from 'src/app/core/services/order.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { environment } from 'src/environments/environment';
import { ClientSummary } from '../../../../core/models/client-summary.model';
import { ClientOrder } from '../../../../core/models/clientOrder.model';
import { getCookieDomainByLang, getEshopUrlByContext } from 'src/app/core/utils/filter.utils';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from '../../../../core/services/storage.service';
import { OrderSummaryComponent } from "./components/order-summary/order-summary.component";
import { DemoService } from "../../../agora/demo/services/demo.service";
import { OrderActionEnum } from "../../../agora/demo/enums/order-action.enum";
import { FidelityService } from "../../../../core/services/fidelity.service";
import { FidelityDelegate } from "../../../../core/models/fidelityDelegate.model";
import { ScreenService } from 'src/app/core/services/screen.service';
import { Subscription } from 'rxjs';
import { DemoClientModel } from 'src/app/features/agora/demo/models/demo-client.model';


@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['./commande.component.scss'],
})
export class CommandeComponent implements OnInit, OnDestroy {

  @ViewChild(OrderSummaryComponent) orderSummaryComponent: any;

  public step = 0;
  public deliveryId = 1;
  public resources: any;
  public loaded = false;

  public mollieError = 0;

  public selectedMethod: string;
  public error: number;
  public locale;

  public demoCode: string;
  public demoCodeUrl: string;

  public isDemoCart = false;
  public isDemoClient = false;

  private summary: ClientSummary;
  public order: ClientOrder;

  private walletIsReset = false;
  isDipChecked = false;
  isPaymentDelegate = false;

  private clientDatas;
  public currentClient:DemoClientModel;

  orderActionEnum = OrderActionEnum;
  delegate: FidelityDelegate;

  public paymentStatus:any = 0;
  public popupWishlistMode = false;

  navigate = true;

  //NG Life Cycle
  subscriptions: Subscription[] = []

  constructor(
    public dataService: GetDatasService,
    public orderService: OrderService,
    public translate: TranslationService,
    private jwtService: JWTService,
    private loader: LoaderService,
    private trigger: TriggerService,
    private route: ActivatedRoute,
    private router: Router,
    private cookies: CookieService,
    private authService: OidcSecurityService,
    private modalService: ModalService,
    private storage: StorageService,
    private demoService: DemoService,
    public fidelityService: FidelityService,
    public screen: ScreenService,
    private breadCrumb:BreadcrumbService
  ) {


  }

  ngOnInit() {

    this.breadCrumb.showBackToCart();

    const sub = this.jwtService.getSubFromToken();

    this.locale = this.cookies.get('v_locale')?.replace('_', '-');
    const paymentRef = this.cookies.get("v_upl");

    if (sub != null && this.dataService.mustLoadProfile()) {
      this.dataService.getClientDatas(sub, true);
    } else {
      this.loader.loaded();
    }

    this.subscriptions.push(this.orderService.panierOrder$.subscribe(res => {
      if (res) {
        this.order = res;
        this.isDemoClient = res.accessLevel > 0;

        this.demoCode = this.cookies.get('demoId') ?? this.order?.demoCode;

        if(this.demoCode) this.demoService.getDemo(this.demoCode, false, false, false);

        this.navigate = this.order.changeStep ?? true;

        const hasTempOrder = this.storage.tempOrder && this.storage.tempOrder!=null;
        const hasTempFreeOrder = hasTempOrder && this.storage.tempOrder?.totalToPay <= 0;

        //Main Blocking status - based on provider's callback QS - will prevent navigation back to cart after payment / payment to delegate / free order / lyfpay payment
        const isPaymentToDelegate = this.route.snapshot.queryParams.toDelegate == 'true';
        const isLyfPay = this.route.snapshot.queryParams.isLyfpay == 'true';
        const isOrderConfirmation = this.route.snapshot.queryParams.confirmation == 'true';

        //

        if(
            (
              ((!paymentRef || paymentRef==null) && !isPaymentToDelegate && !isLyfPay && !isOrderConfirmation)
              && this.order?.itemsTotal==0
              && !hasTempFreeOrder
              && (!hasTempOrder || this.storage.tempOrder?.resolvedId!= this.order?.resolvedId)
            )
            ||
            (!isOrderConfirmation && !isPaymentToDelegate && !isLyfPay && !hasTempOrder && this.order?.itemsTotal==0 && this.order?.flowStatuses?.cart?.status == 0)
          ) this.router.navigate([`/${this.locale}/public/cart`])

        this.demoCode = this.cookies.get('demoId') ?? this.order?.demoCode

        if (res.demoCode) {
          this.demoCode = res.demoCode;
          this.isPaymentDelegate = this.order?.flowStatuses?.payment.status === 11;
          this.deliveryId = this.order?.deliveryTypeId;
          this.isDemoCart = this.order.demoStatusId == 2 && this.isDemoClient;
        } else {
          this.demoCode = this.cookies.get('demoId') ?? this.order?.demoCode;
          if (this.route.snapshot.queryParams.demoCode) this.demoCode = this.route.snapshot.queryParams.demoCode;
        }

        this.resetWalletAmount();

        if(this.step!=5 && this.demoCode && (this.order?.flowStatuses?.dip.status >= 11 && this.navigate)){
          this.isDipChecked = true;
          this.step = 3;
        }

        if (this.step!=5 &&  this.demoCode && (this.order?.flowStatuses?.delivery.status === 10 && this.navigate)) {
          this.deliveryId = this.order.deliveryTypeId;
          this.step = 3;
        }

        if (this.demoCode && this.order?.flowStatuses?.vouchers.status === 10 && this.order?.flowStatuses?.delivery.status === 0 && this.navigate) {
          this.deliveryId = this.order.deliveryTypeId;
          this.isDipChecked = false;
          this.step = 2;
        }

        if(this.order.totalToPay<=0) this.selectedMethod = "no-payment"

        this.navigate = true;
      }
    }));

    this.subscriptions.push(this.orderService.wishlistOrder$.subscribe(res => {
      if(res){
        this.popupWishlistMode = res.orderItemsCount > 0
      } else this.popupWishlistMode = false;
    }))

    this.subscriptions.push(this.loader.loaded$.subscribe(_ => this.loaded = true));

    this.subscriptions.push(this.demoService.clientLinkLightTemp$.subscribe((client) => {
      if(!this.jwtService.isDelegate()){
        this.currentClient = client;
      }
      else this.currentClient = undefined;
    }));

    this.subscriptions.push(this.trigger.triggerUpdateStep$.subscribe(step => {

      if (step == 0) return;
      if (step != 4 && step != 5) {
        this.authService.checkAuth('/', 'desktop').subscribe(data => {
          if (data && data.isAuthenticated) {
            this.dataService.clientData$.subscribe(res => {
              if (res) {
                this.clientDatas = res;
                if (this.clientDatas.contextId != 0 && this.clientDatas.contextId != this.translate.getContextId() && this.order && !(this.order.demoCode)) {
                   this.trigger.redirectUserInProperContext(true);
                }

                if (this.order.contextId != 0 && this.order.contextId != this.translate.getContextId() && this.order && !(this.order.demoCode)) {
                   this.trigger.redirectUserInProperContext(true);
                }
              }
            });
            this.step = step;
            window.scrollTo(0, 0);
          } else {
            this.step = 0;
          }
        });
      } else {
        this.step = step;
      }
    }));

    this.subscriptions.push(this.route.queryParams.subscribe(params => {

      if (params.step && (params.step == 4 || params.step == 5)) {
        this.trigger.requestOrderNextStep(params.step);
      } else {
        this.authService.checkAuth('/', 'desktop').subscribe(data => {
          if (data && data.isAuthenticated) {
            this.trigger.requestOrderNextStep(1);
          } else {
            this.trigger.requestOrderNextStep(0);
          }
        }, err => {
          this.trigger.requestOrderNextStep(0);
        });
      }
    }));

    this.subscriptions.push(this.dataService.summary$.subscribe(res => {
      if (res) {
        this.summary = res;
        this.resetWalletAmount();
      }
    }));

    this.subscriptions.push(this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    }));

    this.subscriptions.push(this.trigger.redirectUserInProperContext$.subscribe(d => {
      if (d == true) {
        this.openRedirectionModal();
      }
    }));

    if (this.demoCode && this.order?.flowStatuses.vouchers.status === 10) {
      this.step = 2;
    }
    if (this.demoCode && this.order?.flowStatuses.delivery.status === 10) {
      this.step = 3;
    }
    if (this.demoCode && this.order?.flowStatuses.delivery.status === 10) {
      this.deliveryId = this.order.deliveryTypeId;
    }
    if (this.demoCode && this.order?.flowStatuses.payment.status === 11) {
      this.isPaymentDelegate = true;
    }
    if (this.route.snapshot.queryParams.demoCode) {
      this.demoCodeUrl = this.route.snapshot.queryParams.demoCode;
    }
    if (this.route.snapshot.queryParams.step) {
      this.step = this.route.snapshot.queryParams.step;
    }
    if (this.route.snapshot.queryParams.isLyfpay) {
      this.step = 5;
    }

    if (this.order && this.order.delegateId) this.fidelityService.getDelegateSiteProfile(this.translate.contextId$, this.order.delegateId, this.dataService.clientData$);

    this.subscriptions.push(this.fidelityService.delegate$.subscribe(res => this.delegate = res));

    window.setTimeout(_ => {
      if(this.order?.id && this.order?.id!=0 && paymentRef && paymentRef!=null) {
        this.subscriptions.push(this.orderService.getPaymentStatus(this.order.resolvedId, paymentRef).subscribe(res => {
            if(res && res.data!=0 && res.data!=2){
              this.paymentStatus = 0;
              this.updateMollieErrorCode(res.data);
            }
        }, err => {
          this.cookies.delete("v_upl", "/", getCookieDomainByLang(this.locale));
        }));
      }
    }, 500);

  }

  goToCart() {
    this.orderService.getGuestOrder();
    this.router.navigate([`/${this.locale}/public/cart`])
  }

  updateStep(event) {
    this.trigger.requestOrderNextStep(event);
  }

  updateDeliveryId(value) {
    this.deliveryId = value;
  }

  updateMollieErrorCode(value) {
    this.mollieError = value;
  }

  changeSelectedMethod(event: string) {
    this.selectedMethod = event;

    if (this.selectedMethod && this.selectedMethod !== 'delivery' && this.order?.flowStatuses?.payment.status === 11) {
      this.demoService.postClientGuestOrderAction(this.orderActionEnum.PAYMENT_UNVALIDATE, this.demoCode, this.order.clientSub).subscribe(res => {
        if (res) {
          this.order = res.data;
          this.isPaymentDelegate = false;
        }
      })
    }
  }

  resetWalletAmount() {
    if (!this.summary || !this.order || this.walletIsReset) {
      return;
    }
    if (this.order.walletAmount < 0) {
      this.orderService.postWalletOrderGuest(0);
      this.walletIsReset = true;
    }
    if (this.order.walletAmount > this.summary.wallet.amount) {
      this.orderService.postWalletOrderGuest(0);
      this.walletIsReset = true;
    }
  }

  setIsDipCheck(value: boolean) {
    this.isDipChecked = value;
  }

  paymentByDelegate(value: boolean) {
    if (value) {
      this.isPaymentDelegate = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  private openRedirectionModal() {
    this.modalService.open('#redirectionModal');
    setTimeout(_ => {
      this.jwtService.deleteTokenCookie();
      this.authService.logoff('desktop');
      window.location.href = getEshopUrlByContext(this.clientDatas.contextId) + '/' + this.clientDatas.locale + '/public/checkout';
    }, 5000);

  }

  public goToDIPBox() {
    const dipBox = document.getElementById("dipBox");
    if(dipBox) dipBox.scrollIntoView({behavior: 'smooth'});
  }

  @HostListener("unload")
  ngOnDestroy(){
    //console.log("commande component destroyed")
    this.breadCrumb.hideBackToCart();
    this.subscriptions.forEach(x => x.unsubscribe())
  }
}
